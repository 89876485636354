<template>
    <div style="padding:20px 24px 40px 24px">
        <div class="globalText">
            本报告将从T细胞免疫功能角度通过多样性、免疫年龄及免疫应激性分析，对机体免疫系统功能进行综合评价。
        </div>
        <div class="resultItem">
            <div class="globalTitle" style="color: #FF830F;">1、T细胞多样性</div>
            <div style="margin:12px 0 16px 0;">
                <span class="globalText middle" style="line-height: 30px;">您的T细胞多样性指数（Shannon’s entropy）为<span
                        style="color:#FF830F;margin-left: 4px;">{{ report.shannonIndex }}</span></span>
            </div>
            <div style="margin:12px 0 16px 0;">
                <span class="globalText middle" style="line-height: 30px;">基于人群T细胞多样性大数据，您目前的多样性评估结果为<span
                        style="color:#FF830F;margin-left: 4px;">{{ report.shannonRes }}</span></span>
                <!-- <span class="index middle">中</span> -->
            </div>
            <div class="resultButton" @click="goto(1)">
                <img :src="ossUrl + 'btn.png'" alt="" class="btnBgc">
                <span class="globalText btnText" style="color: #FFFFFF">T细胞多样性详解</span>
            </div>
        </div>

        <div class="resultItem">
            <div class="globalTitle" style="color: #FF830F;margin-bottom: 16px;">2、T细胞多样性深度分析</div>
            <div class="resultButton" @click="goto(10)">
                <img :src="ossUrl + 'btn.png'" alt="" class="btnBgc">
                <span class="globalText btnText" style="color: #FFFFFF">“免疫力”小宇宙详解</span>
            </div>
        </div>

        <div class="resultItem">
            <div class="globalTitle" style="color: #FF830F;">3、免疫年龄评估</div>
            <div style="margin:12px 0 16px 0;">
                <span class="globalText middle" style="line-height: 30px;">您本次检出的免疫功能水平优于<span
                        style="color:#FF830F;margin:0 4px;">{{ report.immuneAge }}</span>的同年龄段人群</span>

            </div>
            <div class=" resultButton" @click="goto(2)">
                <img :src="ossUrl + 'btn.png'" alt="" class="btnBgc">
                <span class="globalText btnText" style="color: #FFFFFF">免疫年龄评估详解</span>
            </div>
        </div>
        <div class="resultItem">
            <div class="globalTitle" style="color: #FF830F;">4、免疫应激性分析</div>
            <div style="margin:12px 0 16px 0;">
                <span class="globalText middle" style="line-height: 30px;">您本次检测应激性指数为<span
                        style="color:#FF830F;margin:0 4px;">{{ report.stressRes }}</span></span>
            </div>
            <div class="resultButton" @click="goto(3)">
                <img :src="ossUrl + 'btn.png'" alt="" class="btnBgc">
                <span class="globalText btnText">免疫应激性分析详解</span>
            </div>
        </div>
        <div class="resultItem">
            <div class="globalTitle" style="color: #FF830F;">5、免疫演变分析</div>
            <div style="margin:12px 0 16px 0;">
                <span class="globalText middle">记录您不同时间的免疫功能状态，形成免疫功能演变曲线，帮助您更清晰的掌控自身免疫力。</span>
            </div>
            <div v-viewer>
                <img :src="osspath + report.immuneEvolutionPic" alt="" style="width:100%" class="imgTransition" />
            </div>
            <div class="tip">
                横坐标为每次免疫力检测时间，纵坐标为对应的多样性指数，曲线代表免疫功能演变过程。
            </div>
        </div>

        <div class="resultItem">
            <div class="globalTitle" style="color: #FF830F;margin-bottom: 16px;">6、报告小结</div>
            <div class="resultButton" @click="goto(5)">
                <img :src="ossUrl + 'btn.png'" alt="" class="btnBgc">
                <span class="globalText btnText">报告小结详解</span>
            </div>
        </div>

        <div class="resultItem">
            <div class="globalTitle" style="color: #FF830F;margin-bottom: 16px;">7、“免疫力”小宇宙</div>
            <div class="resultButton" @click="goto(6)">
                <img :src="ossUrl + 'btn.png'" alt="" class="btnBgc">
                <span class="globalText btnText">“免疫力”小宇宙详解</span>
            </div>
        </div>



        <div class="resultItem">
            <div class="globalTitle" style="color: #FF830F;margin-bottom: 16px;">8、健康管理建议</div>
            <div class="resultButton" @click="goto(7)">
                <img :src="ossUrl + 'btn.png'" alt="" class="btnBgc">
                <span class="globalText btnText" style="color: #FFFFFF">健康管理建议详解</span>
            </div>
        </div>

        <div class="resultItem">
            <div class="globalTitle" style="color: #FF830F;margin-bottom: 16px;">9、复查建议</div>
            <div class="resultButton" @click="goto(8)">
                <img :src="ossUrl + 'btn.png'" alt="" class="btnBgc">
                <span class="globalText btnText">复查建议详解</span>
            </div>
        </div>

        <div class="resultItem">
            <div class="globalTitle" style="color: #FF830F;margin-bottom: 16px;">10、检测流程及声明</div>
            <div class="resultButton" @click="goto(9)">
                <img :src="ossUrl + 'btn.png'" alt="" class="btnBgc">
                <span class="globalText btnText">检测流程及声明详解</span>
            </div>
        </div>
    </div>
</template>
<script>
import { osspath } from '../../../util/config'
const ossUrl = 'https://kindstarbiotech.oss-cn-hangzhou.aliyuncs.com/rosefinch/static/h5/'
export default {
    data() {
        return {
            ossUrl: ossUrl,
            report: {},
            osspath: osspath,
        }
    },
    created() {
    },
    mounted() {
        this.report = JSON.parse(localStorage.getItem('report'))
        let scrollTop = localStorage.getItem('st')
        if (scrollTop != null && scrollTop != 'undefined') {
            window.scroll({ top: scrollTop, left: 0 });
        } else {
            window.scroll({ top: 0, left: 0 })
        }
    },
    computed: {
    },
    methods: {
        // 记录滚动信息
        getScrollTop() {
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            localStorage.setItem('st', scrollTop)
        },
        goto(index) {
            this.getScrollTop()
            switch (index) {
                case 1:
                    this.$router.push('/report/immuVariety')
                    break;
                case 2:
                    this.$router.push('/report/immuAge')
                    break;
                case 3:
                    this.$router.push("/report/immuStress")
                    break;
                case 5:
                    this.$router.push("/report/immuSummary")
                    break;
                case 6:
                    this.$router.push("/report/immuVisible")
                    break;
                case 7:
                    this.$router.push("/report/immuHealth")
                    break;
                case 8:
                    this.$router.push("/report/immuCheck")
                    break;
                case 9:
                    this.$router.push("/report/immuProcess")
                    break;
                case 10:
                    this.$router.push("/report/immuVarietyDepth")
                    break;
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.resultItem {
    width: 100%;
    padding: 20px 16px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    margin-top: 30px;
}



.result {
    margin: 8px 0 16px 165px;
}

.chart {
    width: 100%;
    margin-bottom: 12px;
}

.resultButton {
    width: 100%;
    position: relative;

    .btnBgc {
        width: 100%;
        display: block;
    }

    .btnText {
        position: absolute;
        left: 24px;
        top: 50%;
        transform: translateY(-50%);
        color: #FFFFFF
    }
}

.tip {
    width: 89%;
    margin: auto;
    font-size: 12px;

    color: #999999;
    line-height: 17px;
    text-align: center;
}
</style>
